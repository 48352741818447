div.portfolio {
  width: 100vw;
  padding-top: 3vh;
  margin-top: 15vh;
  text-align: center;
}

div.portfolio h2.section_heading {
  display: inline-block;
  font-size: 4vh;
  border-bottom: solid 4px rgba(0, 0, 0, 0.6);
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 1.5vh;
}

div.project_filter_container {
  width: 100vw;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

div.project_filter_container button.filter_button {
  height: 5vh;
  width: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 2.5vh;
  box-shadow: none;
  outline: none;
  border: solid 2px #f9375e;
  margin-right: 1.5vw;
  margin-left: 1.5vw;
  font-size: 2.6vh;
  color: #f9375e;
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
}

div.project_filter_container button.filter_button:hover {
  transform: translateY(-0.2vh);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

div.project_filter_container button.filter_button:active {
  transform: translateY(-0.1vh);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

div.project_filter_container button.filter_button.filter_active {
  color: white;
  background-color: #f9375e;
}

div.project_container {
  width: 100vw;
  margin-top: 3vh;
  display: block;
  padding: 0vh 12.5vw 10vh 12.5vw;
  margin-bottom: 15vh;
  margin-top: 5vh;
  position: relative;
}

div.project_container div.project {
  width: 20vw;
  height: 20vw;
  display: inline-block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s;
}

div.project_container div.project.hidden {
  transform: scale(0);
  margin-left: -10vw;
  margin-right: -10vw;
  margin-top: -10vw;
  margin-bottom: -10vw;
}

div.project_container div.project.display_none {
  display: none;
}

div.project_container div.project img {
  width: 20vw;
  height: 20vw;
  transition: 0.6s;
}

div.project_container div.project img.transparent {
  opacity: 0;
}

div.project_container div.project div.info {
  position: absolute;
  top: 5vh;
  display: block;
  text-align: center;
  width: 100%;
  transition: 0.4s;
}

div.project_container div.project div.info.hide {
  transform: translateY(-15vh);
}

div.project_container div.project div.info span.title {
  display: block;
  font-size: 3.2vh;
}

div.project_container div.project div.info span.tech {
  display: block;
  font-size: 2.6vh;
  margin-top: 1vh;
  color: #f9375e;
}

div.project_container div.project button.learn_more_button {
  background-color: #f9375e;
  position: absolute;
  bottom: 5vh;
  left: 0vh;
  width: 9vw;
  height: 4vh;
  font-size: 2.2vh;
  border-radius: 1vh;
  border: none;
  margin-left: 5.5vw;
  color: white;
  transition: 0.4s;
  cursor: pointer;
  outline: none;
}

div.project_container div.project button.learn_more_button:hover {
  transform: translateY(-0.3vh);
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  color: #f9375e;
  border: solid 2px #f9375e;
  background-color: white;
}

div.project_container div.project button.learn_more_button:active {
  transform: translateY(-0.115vh);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #f9375e;
  border: solid 2px #f9375e;
  background-color: white;
}

div.project_container div.project button.learn_more_button.hide {
  transform: translateY(10vh);
}

@media (max-width: 1024px) {
  div.portfolio {
    margin-top: 5vh;
  }

  div.portfolio h2.section_heading {
  }

  div.project_filter_container {
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }

  div.project_filter_container button.filter_button {
    height: 4vh;
    margin-right: 2vw;
    margin-left: 2vw;
    font-size: 2vh;
    margin-bottom: 1vh;
    padding-left: 3vw;
    padding-right: 2vw;
  }

  div.project_filter_container button.filter_button:hover {
    transform: translateY(0vh);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  div.project_filter_container button.filter_button:active {
    transform: translateY(0vh);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  div.project_container {
    padding: 0vh 5vw 5vh 5vw;
    margin-bottom: 8vh;
    margin-top: 3vh;
    display: block;
  }

  div.project_container div.project {
    width: 60vw;
    height: 60vw;
    margin-left: 15vw;
    display: block;
    margin-bottom: 2vh;
  }

  div.project_container div.project.hidden {
    margin-left: -30vw;
    margin-right: -30vw;
    margin-top: -30vw;
    margin-bottom: -30vw;
  }

  div.project_container div.project.display_none {
    display: none;
  }

  div.project_container div.project img {
    width: 60vw;
    height: 60vw;
    transition: 0.6s;
  }

  div.project_container div.project img.transparent {
    opacity: 0;
  }

  div.project_container div.project div.info {
    display: none;
  }

  div.project_container div.project button.learn_more_button {
    display: none;
  }
}
