div.scroll_to_top {
  width: 8vh;
  height: 8vh;
  position: fixed;
  bottom: 4vh;
  right: 4vh;
  border: solid 2px black;
  border-radius: 20vh;
  cursor: pointer;
  background-color: white;
  z-index: 10;
  opacity: 0.85;
  transition: 0.3s;
}

div.scroll_to_top.hidden {
  opacity: 0;
}

div.scroll_to_top img.arrow {
  width: 50%;
  height: 50%;
  margin-top: 23%;
  margin-left: 25%;
}

@media (max-width: 1024px) {
  div.scroll_to_top {
    width: 6vh;
    height: 6vh;
    bottom: 2vh;
    right: 2vh;
  }
}
