div.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.38);
    z-index: 10;
    cursor: pointer;
    transition: 0.5s;
}
