.hidden {
  visibility: hidden;
}

div.timeline_section {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.02);
  height: 100vh;
  margin-top: 5vh;
  padding-top: 0vh;
  position: relative;
}

div.timeline_section div.portfolio_button {
  width: 7vw;
  height: 7vh;
  z-index: 100;
  position: absolute;
  top: 91vh;
  left: 46vw;
  text-align: center;
  cursor: pointer;
  -webkit-animation: pulse linear 2s infinite;
  animation: pulse linear 2s infinite;
}

div.timeline_section div.portfolio_button span.button_title {
  color: black;
  opacity: 0.7;
  font-size: 2.4vh;
}

div.timeline_section div.portfolio_button img.button_arrow {
  width: 3vw;
  opacity: 0.7;
  margin-top: 1.2vh;
}

div.timeline_map {
  width: 100vw;
  height: 16.5vh;
  padding-top: 1.5vh;
  vertical-align: top;
  text-align: center;
}

img.arrow {
  height: 3vh;
  display: inline-block;
  cursor: pointer;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-top: 7.5vh;
  vertical-align: top;
  transition: 0.3s;
}

div.point_in_time {
  display: inline-block;
  width: 3vh;
  height: 20vh;
  vertical-align: top;
  cursor: pointer;
}

img.logo {
  height: 2.8vh;
  display: block;
  display: inline-block;
  margin-top: 2.5vh;
  transition: 0.7s;
}

img.point_in_time {
  height: 2vh;
  display: inline-block;
  margin-top: 1.5vh;
  margin-bottom: 0.4vh;
  transition: 0.7s;
  z-index: 2;
  position: relative;
}

img.point_in_time.active {
  height: 3.5vh;
  margin-top: 0.5vh;
  margin-bottom: 0;
}

img.point_in_time.rotate_clockwise {
  transform: rotate(-90deg);
}

@keyframes next_point_in_time_animate {
  0% {
    height: 2vh;
  }
  50% {
    height: 3.5vh;
    margin-top: 0.5vh;
    margin-bottom: -0.1vh;
    margin-left: -0.4vh;
  }
  100% {
    height: 2vh;
  }
}

span.title {
  text-align: center;
  color: #c4c4c4;
  display: block;
  font-size: 2vh;
  width: 4vw;
  transform: translateX(-1vw);
  margin-top: 0.5vh;
}

span.title {
  color: #c4c4c4;
}

span.title.active {
  color: #fa3f70;
}

div.timeline_section div.connector {
  display: inline-block;
  height: 0.5vh;
  background-color: #c4c4c4;
  vertical-align: top;
  margin-top: 7.8vh;
  margin-left: -0.9vw;
  margin-right: -0.9vw;
  z-index: -1;
  position: relative;
  transition: 0.7s;
  z-index: 1;
}

div.timeline_section div.connector.display_none {
  display: none;
}

div.timeline_section div.connector.active {
  background-color: #fa3f70;
}

div.timeline_section div.connector.active div.connector_moving {
  height: 100%;
  width: 100%;
  transition: 0.1s;
  background-color: #fa3f70;
  transition: 1s;
}

div.preview_window {
  height: 72vh;
  width: 95vw;
  margin-top: 3vh;
  display: block;
}

div.preview_window div.left {
  width: 40vw;
  height: 72vh;
  display: inline-block;
  vertical-align: top;
}

div.preview_window div.left div.info_container {
  width: 37vw;
  height: 30vh;
  display: block;
  margin-top: 15vh;
  background-image: linear-gradient(to right, #f9375e, #fa6251);

  border-top-right-radius: 100vh;
  border-bottom-right-radius: 100vh;
  transition: 0.3s ease-in;
}

div.preview_window div.left div.info_container.slide_out {
  transform: translateX(-37vw);
}

div.preview_window div.left div.info_container div.icon_container {
  height: 30vh;
  width: 7vw;
  display: inline-block;
  padding-top: 2.7vh;
}

div.preview_window
  div.left
  div.info_container
  div.icon_container
  img.side_logo {
  height: 3.5vh;
  float: right;
  display: block;
  clear: both;
  margin-bottom: 2.95vh;
  margin-right: 2vw;
}

div.preview_window div.left div.info_container div.label_container {
  height: 30vh;
  width: 12vw;
  display: inline-block;
  padding-top: 2.7vh;
}

div.preview_window
  div.left
  div.info_container
  div.label_container
  span.side_label {
  width: 100%;
  height: 4vh;
  float: right;
  display: block;
  margin-bottom: 2.7vh;
  font-size: 2.1vh;
  margin-top: 0;
  vertical-align: bottom;
}

div.preview_window div.left div.info_container img.org_logo {
  height: 22vh;
  margin-right: 4vh;
  margin-top: 4vh;
  float: right;
  border-radius: 100vh;
}

div.preview_window div.left div.info_container span.side_label {
  display: inline-block;
  width: 17vw;
  height: 5vh;
  color: white;
  vertical-align: top;
  font-size: 2.4vh;
  margin-top: 2vh;
  font-weight: 500;
  left: 5vw;
}

div.preview_window div.right {
  width: 55vw;
  height: 72vh;
  display: inline-block;
  position: relative;
}

div.preview_window div.right h2 {
  font-size: 2.3vh;
  margin-left: 2vw;
  margin-right: 2vw;
}

div.preview_window div.right p {
  font-size: 1.95vh;
  line-height: 2.7vh;
  margin-left: 2vw;
  margin-right: 2vw;
  font-weight: 300;
}

div.preview_window div.right div.description {
  max-height: 46.5vh;
  overflow-y: scroll;
}

div.preview_window div.right div.description h2 {
  margin-bottom: 3.5vh;
  margin-top: 1.5vh;
}

div.preview_window div.right div.description::-webkit-scrollbar {
  display: block;
  height: 1vh;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2vh;
  margin-top: 3vh;
  width: 0.5vw;
}

div.preview_window div.right div.description::-webkit-scrollbar-thumb {
  background: #fa3f70;
  border-radius: 2vh;
}

div.preview_window div.right div.skills {
  height: 22vh;
  margin-top: 3vh;
}

div.preview_window div.right div.scroll_container {
  height: 20vh;
  width: 45vw;
  display: block;
  overflow-x: hidden;
  white-space: nowrap;
  overflow-y: hidden;
  margin-left: 5vh;
}

div.preview_window div.right div.scroll_container::-webkit-scrollbar {
  display: block;
  height: 1vh;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2vh;
  margin-top: 3vh;
}

div.preview_window div.right div.scroll_container::-webkit-scrollbar-thumb {
  background: #fa3f70;
  border-radius: 2vh;
}

@media (max-width: 1024px) {
  div.timeline_section {
    margin-top: 0;
    padding-top: 1vh;
    height: auto;
    position: relative;
  }

  div.timeline_map {
    height: 12vh;
  }

  img.arrow {
    height: 2vh;
    margin-top: 3vh;
    display: none;
  }

  div.point_in_time {
    width: 2vh;
    height: 8vh;
  }

  img.logo {
    height: 2vh;
    margin-top: 1vh;
    transition: 0.7s;
  }

  img.point_in_time {
    height: vh;
    margin-top: 1.7vh;
    margin-bottom: 0.4vh;
  }

  img.point_in_time.active {
    height: 3vh;
    margin-top: 1.2vh;
  }

  img.point_in_time.rotate_clockwise {
    transform: rotate(-90deg);
  }

  img.point_in_time.next_point_in_time {
    animation-name: next_point_in_time_animate;
    animation-duration: 1.9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  @keyframes next_point_in_time_animate {
    0% {
      height: 2vh;
    }
    50% {
      height: 3vh;
      margin-top: 1.2vh;
      margin-bottom: -0.1vh;
      margin-left: -0.4vh;
    }
    100% {
      height: 2vh;
    }
  }

  span.title {
    text-align: center;
    color: #c4c4c4;
    display: block;
    font-size: 1.6vh;
  }

  span.title {
    color: #c4c4c4;
  }

  span.title.active {
    color: #fa3f70;
  }

  div.timeline_section div.connector {
    height: 0.5vh;
    margin-top: 6vh;
    margin-left: -1.7vw;
    margin-right: -1.7vw;
  }

  div.timeline_section div.connector.display_none {
    display: none;
  }

  div.timeline_section div.connector.active {
    background-color: #fa3f70;
  }

  div.timeline_section div.connector.active div.connector_moving {
    height: 100%;
    width: 100%;
    transition: 0.1s;
    background-color: #fa3f70;
    transition: 1s;
  }

  div.preview_window {
    height: auto;
    width: 95vw;
    margin-top: 0;
  }

  div.preview_window div.left {
    width: 90vw;
    height: auto;
    display: block;
  }

  div.preview_window div.left div.info_container {
    width: 98vw;
    height: 20vh;
    display: block;
    margin-top: 2vh;
  }

  div.preview_window div.left div.info_container.slide_out {
    transform: translateX(-98vw);
  }

  div.preview_window div.left div.info_container div.icon_container {
    height: 30vh;
    width: 10vw;
    display: inline-block;
    padding-top: 2.7vh;
  }

  div.preview_window
    div.left
    div.info_container
    div.icon_container
    img.side_logo {
    height: 2vh;
    margin-top: 0vh;
    margin-bottom: 1.5vh;
  }

  div.preview_window div.left div.info_container div.label_container {
    width: 40vw;
  }

  div.preview_window
    div.left
    div.info_container
    div.label_container
    span.side_label {
    font-size: 1.4vh;
    margin-bottom: 1.5vh;
    margin-top: 0vh;
    height: 2vh;
    padding-top: 0.3vh;
  }

  div.preview_window div.left div.info_container img.org_logo {
    height: 15vh;
    margin-top: 2.5vh;
    margin-right: 2.5vh;
  }

  div.preview_window div.right {
    width: 100vw;
    height: auto;
  }

  div.preview_window div.right div.description h2 {
    margin-top: 3vh;
    font-size: 2.4vh;
    margin-bottom: 2.5vh;
  }

  div.preview_window div.right div.description {
    max-height: none;
    overflow-y: scroll;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  div.preview_window div.right div.description p {
    font-size: 1.6vh;
  }

  div.preview_window div.right div.skills {
    height: 21.5vh;
    margin-top: 1vh;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-bottom: 0;
  }

  div.preview_window div.right div.scroll_container {
    height: 15vh;
    width: 94vw;
    margin-left: 0;
    padding-left: 0;
    z-index: 10;
    position: relative;
    overflow-x: scroll;
  }

  div.preview_window div.right div.scroll_container::-webkit-scrollbar {
    display: none;
  }

  div.timeline_section div.portfolio_button {
    position: absolute;
    width: 20vw;
    height: 7vh;
    z-index: 100;
    left: 40vw;
    bottom: 0vh;
    top: auto;
  }

  div.timeline_section div.portfolio_button span.button_title {
    color: black;
    opacity: 0.7;
    font-size: 2.4vh;
  }

  div.timeline_section div.portfolio_button img.button_arrow {
    width: 10vw;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: translateY(0vh);
  }
  50% {
    transform: translateY(-1.5vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes pulse {
  0% {
    transform: translateY(0vh);
  }
  50% {
    transform: translateY(-1.5vh);
  }
  100% {
    transform: translateY(0vh);
  }
}
