div.skill {
  width: 8.5vw;
  height: auto;
  display: inline-block;
  border-radius: 2vh;
  margin-bottom: 2vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  text-align: center;
  margin-right: 1vw;
}

img.logo {
  width: 6vw;
  height: auto;
  max-height: 6vw;
  display: inline-block;
}

span.name {
  display: block;
  line-height: 2.2vh;
  font-size: 1.8vh;
  text-align: center;
  color: black;
}

@media screen and (max-width: 1024px) {
  div.skill {
    margin-left: 0vw;
    width: 18vw;
    display: inline-block;
    border-radius: 2vh;
    margin-bottom: 2vh;
    padding-top: 0.5vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    text-align: center;
  }

  img.logo {
    width: 7vh;
    height: auto;
    max-height: 10vh;
    display: inline-block;
  }

  span.name {
    display: block;
    line-height: 2.2vh;
    font-size: 1.3vh;
    text-align: center;
    color: black;
  }
}
