* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.navbar__navbar__2EPET {
  width: 100vw;
  height: 14vh;
}

img.navbar__logo__2Tw3_ {
  height: 9vh;
  width: 9vh;
  margin-left: 5vh;
  margin-top: 5vh;
}

span.navbar__nav_item__2TUA9 {
  text-decoration: none;
  float: right;
  font-size: 2.6vh;
  line-height: 3vh;
  margin-top: 5vh;
  margin-right: 5vw;
  color: black;
  padding-bottom: 1vh;
}

a.navbar__nav_item__2TUA9 {
  text-decoration: none;
  float: right;
  font-size: 2.6vh;
  line-height: 3vh;
  margin-top: 5vh;
  margin-right: 5vw;
  color: black;
  padding-bottom: 1vh;
}

span.navbar__nav_item__2TUA9:hover {
  border-bottom: solid 2px black;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  img.navbar__logo__2Tw3_ {
    height: 6vh;
    width: 6vh;
    margin-left: 3vh;
    margin-top: 3vh;
  }

  span.navbar__nav_item__2TUA9 {
    margin-top: 4vh;
    margin-right: 6vw;
    font-weight: 300;
    font-size: 2.2vh;
  }
}

div.footer__footer__3gaM7 {
  height: 10vh;
  width: 100vw;
  background-image: linear-gradient(to right, #f9375e, #fa6251);
  margin-top: 0vh;
  position: relative;
}

span.footer__name__WNDVM {
  display: block;
  color: white;
  text-align: center;
  font-size: 2vh;
  position: absolute;
  bottom: 3.5vh;
  width: 100vw;
  font-weight: 500;
}

div.summary__main_details_container__1azIp {
  width: 100vw;
  margin-top: 0vh;
  height: 81vh;
  position: relative;
}

div.summary__main_details_container__1azIp h1 {
  margin-top: 0;
  text-align: center;
  font-weight: 400;
  font-size: 40px;
  margin-right: 2vw;
  margin-left: 2vw;
}

img.summary__profile_pic__3EAwW {
  width: 12vw;
  height: 12vw;
  object-fit: cover;
  border: solid 3px red;
  border-radius: 100vw;
  padding: 3px;
  margin-left: 44vw;
  margin-top: 3vh;
}

div.summary__main_details_container__1azIp h5.summary__subtle_text__3_rJA {
  font-weight: 300;
  color: grey;
  text-align: center;
  font-size: 15px;
  margin-top: 0;
}

div.summary__main_details_container__1azIp h2 {
  font-size: 3.4vh;
  margin-top: 3vh;
}

div.summary__main_details_container__1azIp h3 {
  font-size: 2.8vh;
  margin-top: 0vh;
}
div.summary__icon_container__15GHe {
  display: block;
  height: 7vh;
  text-align: center;
  margin-top: 3vh;
}

div.summary__icon_container__15GHe img.summary__icon__FmQe- {
  margin-top: 0.5vh;
  display: inline-block;
  height: 5vh;
  margin-left: 1vw;
  margin-right: 1vw;
  cursor: pointer;
  transition: 0.3s;
}

div.summary__icon_container__15GHe img.summary__icon__FmQe-:hover {
  -webkit-transform: translateY(-0.7vh);
          transform: translateY(-0.7vh);
}

div.summary__my_story_button__HBDPn {
  margin-top: 10vh;
  cursor: pointer;
  -webkit-animation: summary__pulse__2l20J linear 2s infinite;
  animation: summary__pulse__2l20J linear 2s infinite;
}

div.summary__my_story_button__HBDPn h4 {
  text-align: center;
  font-weight: 400;
  font-size: 2.5vh;
  margin-bottom: 2vh;
  margin-top: 5vh;
  opacity: 0.7;
}

div.summary__my_story_button__HBDPn img.summary__arrow__2owi4 {
  width: 4vw;
  display: block;
  margin-left: 48vw;
  margin-top: 0vh;
  opacity: 0.6;
}

img.summary__three_lines__2Hryg {
  height: 58vh;
  position: absolute;
  bottom: 0;
  right: 0;
}

h2 {
  margin-top: 2vh;
  text-align: center;
  font-weight: 400;
}

h3 {
  text-align: center;
  font-weight: 400;
}

h3.summary__light__2yzBg {
  font-weight: 300;
}

div.summary__summary_text__mSpES {
  width: 100vw;
  height: 24vh;
  margin-top: 0vh;
  background-image: linear-gradient(to right, #f9375e, #fb8847);
  padding: 2vh 5vw 2vh 5vw;
  color: white;
}

div.summary__summary_text__mSpES p {
  text-align: center;
  font-size: 2.5vh;
  line-height: 4vh;
}

@media screen and (max-width: 700px) {
  div.summary__main_details_container__1azIp {
    height: 85.5vh;
  }

  div.summary__main_details_container__1azIp h5.summary__subtle_text__3_rJA {
    font-size: 12px;
    margin-top: 0;
  }

  div.summary__main_details_container__1azIp h1 {
    margin-top: 0vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-weight: 300;
    line-height: 30px;
    font-size: 20px;
  }

  img.summary__profile_pic__3EAwW {
    width: 40vw;
    height: 40vw;
    margin-left: 30vw;
  }

  div.summary__main_details_container__1azIp h2 {
    font-size: 3.4vh;
    margin-top: 1.5vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-weight: 300;
  }

  div.summary__main_details_container__1azIp h3 {
    font-size: 2.8vh;
    margin-top: 0vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-weight: 300;
    line-height: 4vh;
  }
  div.summary__icon_container__15GHe {
    display: block;
    height: 7vh;
    text-align: center;
    margin-top: 3vh;
  }

  div.summary__icon_container__15GHe img.summary__icon__FmQe- {
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 0.5vh;
    display: inline-block;
    height: 3.8vh;
  }

  div.summary__icon_container__15GHe img.summary__icon__FmQe-:hover {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  div.summary__my_story_button__HBDPn {
    margin-top: 5vh;
    text-align: center;
  }

  div.summary__my_story_button__HBDPn h4 {
  }

  div.summary__my_story_button__HBDPn img.summary__arrow__2owi4 {
    width: 8vw;
    margin-left: 45vw;
  }

  img.summary__three_lines__2Hryg {
    height: 18vh;
    z-index: -1;
    opacity: 0.9;
  }

  div.summary__summary_text__mSpES {
    width: 100vw;
    height: 37vh;
    padding: 1vh 2vw 1vh 2vw;
    color: white;
  }

  div.summary__summary_text__mSpES p {
    text-align: center;
    font-size: 2.2vh;
    line-height: 4vh;
  }
}

@-webkit-keyframes summary__pulse__2l20J {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  50% {
    -webkit-transform: translateY(-1.5vh);
            transform: translateY(-1.5vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

@keyframes summary__pulse__2l20J {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  50% {
    -webkit-transform: translateY(-1.5vh);
            transform: translateY(-1.5vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

@media screen and (max-width: 1100px) {
  img.summary__three_lines__2Hryg {
    height: 24vh;
  }
}

.timeline__hidden__3EqsV {
  visibility: hidden;
}

div.timeline__timeline_section__3Sk7L {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.02);
  height: 100vh;
  margin-top: 5vh;
  padding-top: 0vh;
  position: relative;
}

div.timeline__timeline_section__3Sk7L div.timeline__portfolio_button__6AeJb {
  width: 7vw;
  height: 7vh;
  z-index: 100;
  position: absolute;
  top: 91vh;
  left: 46vw;
  text-align: center;
  cursor: pointer;
  -webkit-animation: timeline__pulse__2AtrD linear 2s infinite;
  animation: timeline__pulse__2AtrD linear 2s infinite;
}

div.timeline__timeline_section__3Sk7L div.timeline__portfolio_button__6AeJb span.timeline__button_title__1kbuy {
  color: black;
  opacity: 0.7;
  font-size: 2.4vh;
}

div.timeline__timeline_section__3Sk7L div.timeline__portfolio_button__6AeJb img.timeline__button_arrow__3zjGM {
  width: 3vw;
  opacity: 0.7;
  margin-top: 1.2vh;
}

div.timeline__timeline_map__nJlg5 {
  width: 100vw;
  height: 16.5vh;
  padding-top: 1.5vh;
  vertical-align: top;
  text-align: center;
}

img.timeline__arrow__2Xhjf {
  height: 3vh;
  display: inline-block;
  cursor: pointer;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-top: 7.5vh;
  vertical-align: top;
  transition: 0.3s;
}

div.timeline__point_in_time__187Jy {
  display: inline-block;
  width: 3vh;
  height: 20vh;
  vertical-align: top;
  cursor: pointer;
}

img.timeline__logo__dR6Ne {
  height: 2.8vh;
  display: block;
  display: inline-block;
  margin-top: 2.5vh;
  transition: 0.7s;
}

img.timeline__point_in_time__187Jy {
  height: 2vh;
  display: inline-block;
  margin-top: 1.5vh;
  margin-bottom: 0.4vh;
  transition: 0.7s;
  z-index: 2;
  position: relative;
}

img.timeline__point_in_time__187Jy.timeline__active__3Cd73 {
  height: 3.5vh;
  margin-top: 0.5vh;
  margin-bottom: 0;
}

img.timeline__point_in_time__187Jy.timeline__rotate_clockwise__2cupG {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

@-webkit-keyframes timeline__next_point_in_time_animate__1MIKs {
  0% {
    height: 2vh;
  }
  50% {
    height: 3.5vh;
    margin-top: 0.5vh;
    margin-bottom: -0.1vh;
    margin-left: -0.4vh;
  }
  100% {
    height: 2vh;
  }
}

@keyframes timeline__next_point_in_time_animate__1MIKs {
  0% {
    height: 2vh;
  }
  50% {
    height: 3.5vh;
    margin-top: 0.5vh;
    margin-bottom: -0.1vh;
    margin-left: -0.4vh;
  }
  100% {
    height: 2vh;
  }
}

span.timeline__title__6kyrJ {
  text-align: center;
  color: #c4c4c4;
  display: block;
  font-size: 2vh;
  width: 4vw;
  -webkit-transform: translateX(-1vw);
          transform: translateX(-1vw);
  margin-top: 0.5vh;
}

span.timeline__title__6kyrJ {
  color: #c4c4c4;
}

span.timeline__title__6kyrJ.timeline__active__3Cd73 {
  color: #fa3f70;
}

div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL {
  display: inline-block;
  height: 0.5vh;
  background-color: #c4c4c4;
  vertical-align: top;
  margin-top: 7.8vh;
  margin-left: -0.9vw;
  margin-right: -0.9vw;
  z-index: -1;
  position: relative;
  transition: 0.7s;
  z-index: 1;
}

div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL.timeline__display_none__1aYTe {
  display: none;
}

div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL.timeline__active__3Cd73 {
  background-color: #fa3f70;
}

div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL.timeline__active__3Cd73 div.timeline__connector_moving__1MISA {
  height: 100%;
  width: 100%;
  transition: 0.1s;
  background-color: #fa3f70;
  transition: 1s;
}

div.timeline__preview_window__2t_HG {
  height: 72vh;
  width: 95vw;
  margin-top: 3vh;
  display: block;
}

div.timeline__preview_window__2t_HG div.timeline__left__3OKxX {
  width: 40vw;
  height: 72vh;
  display: inline-block;
  vertical-align: top;
}

div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP {
  width: 37vw;
  height: 30vh;
  display: block;
  margin-top: 15vh;
  background-image: linear-gradient(to right, #f9375e, #fa6251);

  border-top-right-radius: 100vh;
  border-bottom-right-radius: 100vh;
  transition: 0.3s ease-in;
}

div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP.timeline__slide_out__1MoWo {
  -webkit-transform: translateX(-37vw);
          transform: translateX(-37vw);
}

div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP div.timeline__icon_container__3IrwE {
  height: 30vh;
  width: 7vw;
  display: inline-block;
  padding-top: 2.7vh;
}

div.timeline__preview_window__2t_HG
  div.timeline__left__3OKxX
  div.timeline__info_container__1GwEP
  div.timeline__icon_container__3IrwE
  img.timeline__side_logo__29ya4 {
  height: 3.5vh;
  float: right;
  display: block;
  clear: both;
  margin-bottom: 2.95vh;
  margin-right: 2vw;
}

div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP div.timeline__label_container__2tTt7 {
  height: 30vh;
  width: 12vw;
  display: inline-block;
  padding-top: 2.7vh;
}

div.timeline__preview_window__2t_HG
  div.timeline__left__3OKxX
  div.timeline__info_container__1GwEP
  div.timeline__label_container__2tTt7
  span.timeline__side_label__iosgm {
  width: 100%;
  height: 4vh;
  float: right;
  display: block;
  margin-bottom: 2.7vh;
  font-size: 2.1vh;
  margin-top: 0;
  vertical-align: bottom;
}

div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP img.timeline__org_logo__2soMc {
  height: 22vh;
  margin-right: 4vh;
  margin-top: 4vh;
  float: right;
  border-radius: 100vh;
}

div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP span.timeline__side_label__iosgm {
  display: inline-block;
  width: 17vw;
  height: 5vh;
  color: white;
  vertical-align: top;
  font-size: 2.4vh;
  margin-top: 2vh;
  font-weight: 500;
  left: 5vw;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG {
  width: 55vw;
  height: 72vh;
  display: inline-block;
  position: relative;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG h2 {
  font-size: 2.3vh;
  margin-left: 2vw;
  margin-right: 2vw;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG p {
  font-size: 1.95vh;
  line-height: 2.7vh;
  margin-left: 2vw;
  margin-right: 2vw;
  font-weight: 300;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__description__3BTdt {
  max-height: 46.5vh;
  overflow-y: scroll;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__description__3BTdt h2 {
  margin-bottom: 3.5vh;
  margin-top: 1.5vh;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__description__3BTdt::-webkit-scrollbar {
  display: block;
  height: 1vh;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2vh;
  margin-top: 3vh;
  width: 0.5vw;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__description__3BTdt::-webkit-scrollbar-thumb {
  background: #fa3f70;
  border-radius: 2vh;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__skills__1FW00 {
  height: 22vh;
  margin-top: 3vh;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__scroll_container__nerlu {
  height: 20vh;
  width: 45vw;
  display: block;
  overflow-x: hidden;
  white-space: nowrap;
  overflow-y: hidden;
  margin-left: 5vh;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__scroll_container__nerlu::-webkit-scrollbar {
  display: block;
  height: 1vh;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2vh;
  margin-top: 3vh;
}

div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__scroll_container__nerlu::-webkit-scrollbar-thumb {
  background: #fa3f70;
  border-radius: 2vh;
}

@media (max-width: 1024px) {
  div.timeline__timeline_section__3Sk7L {
    margin-top: 0;
    padding-top: 1vh;
    height: auto;
    position: relative;
  }

  div.timeline__timeline_map__nJlg5 {
    height: 12vh;
  }

  img.timeline__arrow__2Xhjf {
    height: 2vh;
    margin-top: 3vh;
    display: none;
  }

  div.timeline__point_in_time__187Jy {
    width: 2vh;
    height: 8vh;
  }

  img.timeline__logo__dR6Ne {
    height: 2vh;
    margin-top: 1vh;
    transition: 0.7s;
  }

  img.timeline__point_in_time__187Jy {
    height: vh;
    margin-top: 1.7vh;
    margin-bottom: 0.4vh;
  }

  img.timeline__point_in_time__187Jy.timeline__active__3Cd73 {
    height: 3vh;
    margin-top: 1.2vh;
  }

  img.timeline__point_in_time__187Jy.timeline__rotate_clockwise__2cupG {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }

  img.timeline__point_in_time__187Jy.timeline__next_point_in_time__2r3PR {
    -webkit-animation-name: timeline__next_point_in_time_animate__1MIKs;
            animation-name: timeline__next_point_in_time_animate__1MIKs;
    -webkit-animation-duration: 1.9s;
            animation-duration: 1.9s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  @-webkit-keyframes timeline__next_point_in_time_animate__1MIKs {
    0% {
      height: 2vh;
    }
    50% {
      height: 3vh;
      margin-top: 1.2vh;
      margin-bottom: -0.1vh;
      margin-left: -0.4vh;
    }
    100% {
      height: 2vh;
    }
  }

  @keyframes timeline__next_point_in_time_animate__1MIKs {
    0% {
      height: 2vh;
    }
    50% {
      height: 3vh;
      margin-top: 1.2vh;
      margin-bottom: -0.1vh;
      margin-left: -0.4vh;
    }
    100% {
      height: 2vh;
    }
  }

  span.timeline__title__6kyrJ {
    text-align: center;
    color: #c4c4c4;
    display: block;
    font-size: 1.6vh;
  }

  span.timeline__title__6kyrJ {
    color: #c4c4c4;
  }

  span.timeline__title__6kyrJ.timeline__active__3Cd73 {
    color: #fa3f70;
  }

  div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL {
    height: 0.5vh;
    margin-top: 6vh;
    margin-left: -1.7vw;
    margin-right: -1.7vw;
  }

  div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL.timeline__display_none__1aYTe {
    display: none;
  }

  div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL.timeline__active__3Cd73 {
    background-color: #fa3f70;
  }

  div.timeline__timeline_section__3Sk7L div.timeline__connector__3nVWL.timeline__active__3Cd73 div.timeline__connector_moving__1MISA {
    height: 100%;
    width: 100%;
    transition: 0.1s;
    background-color: #fa3f70;
    transition: 1s;
  }

  div.timeline__preview_window__2t_HG {
    height: auto;
    width: 95vw;
    margin-top: 0;
  }

  div.timeline__preview_window__2t_HG div.timeline__left__3OKxX {
    width: 90vw;
    height: auto;
    display: block;
  }

  div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP {
    width: 98vw;
    height: 20vh;
    display: block;
    margin-top: 2vh;
  }

  div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP.timeline__slide_out__1MoWo {
    -webkit-transform: translateX(-98vw);
            transform: translateX(-98vw);
  }

  div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP div.timeline__icon_container__3IrwE {
    height: 30vh;
    width: 10vw;
    display: inline-block;
    padding-top: 2.7vh;
  }

  div.timeline__preview_window__2t_HG
    div.timeline__left__3OKxX
    div.timeline__info_container__1GwEP
    div.timeline__icon_container__3IrwE
    img.timeline__side_logo__29ya4 {
    height: 2vh;
    margin-top: 0vh;
    margin-bottom: 1.5vh;
  }

  div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP div.timeline__label_container__2tTt7 {
    width: 40vw;
  }

  div.timeline__preview_window__2t_HG
    div.timeline__left__3OKxX
    div.timeline__info_container__1GwEP
    div.timeline__label_container__2tTt7
    span.timeline__side_label__iosgm {
    font-size: 1.4vh;
    margin-bottom: 1.5vh;
    margin-top: 0vh;
    height: 2vh;
    padding-top: 0.3vh;
  }

  div.timeline__preview_window__2t_HG div.timeline__left__3OKxX div.timeline__info_container__1GwEP img.timeline__org_logo__2soMc {
    height: 15vh;
    margin-top: 2.5vh;
    margin-right: 2.5vh;
  }

  div.timeline__preview_window__2t_HG div.timeline__right__3xjDG {
    width: 100vw;
    height: auto;
  }

  div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__description__3BTdt h2 {
    margin-top: 3vh;
    font-size: 2.4vh;
    margin-bottom: 2.5vh;
  }

  div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__description__3BTdt {
    max-height: none;
    overflow-y: scroll;
    padding-left: 3vw;
    padding-right: 3vw;
  }

  div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__description__3BTdt p {
    font-size: 1.6vh;
  }

  div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__skills__1FW00 {
    height: 21.5vh;
    margin-top: 1vh;
    padding-left: 3vw;
    padding-right: 3vw;
    margin-bottom: 0;
  }

  div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__scroll_container__nerlu {
    height: 15vh;
    width: 94vw;
    margin-left: 0;
    padding-left: 0;
    z-index: 10;
    position: relative;
    overflow-x: scroll;
  }

  div.timeline__preview_window__2t_HG div.timeline__right__3xjDG div.timeline__scroll_container__nerlu::-webkit-scrollbar {
    display: none;
  }

  div.timeline__timeline_section__3Sk7L div.timeline__portfolio_button__6AeJb {
    position: absolute;
    width: 20vw;
    height: 7vh;
    z-index: 100;
    left: 40vw;
    bottom: 0vh;
    top: auto;
  }

  div.timeline__timeline_section__3Sk7L div.timeline__portfolio_button__6AeJb span.timeline__button_title__1kbuy {
    color: black;
    opacity: 0.7;
    font-size: 2.4vh;
  }

  div.timeline__timeline_section__3Sk7L div.timeline__portfolio_button__6AeJb img.timeline__button_arrow__3zjGM {
    width: 10vw;
  }
}

@-webkit-keyframes timeline__pulse__2AtrD {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  50% {
    -webkit-transform: translateY(-1.5vh);
            transform: translateY(-1.5vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

@keyframes timeline__pulse__2AtrD {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  50% {
    -webkit-transform: translateY(-1.5vh);
            transform: translateY(-1.5vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

div.skill__skill__nfv-O {
  width: 8.5vw;
  height: auto;
  display: inline-block;
  border-radius: 2vh;
  margin-bottom: 2vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  text-align: center;
  margin-right: 1vw;
}

img.skill__logo__6CIdW {
  width: 6vw;
  height: auto;
  max-height: 6vw;
  display: inline-block;
}

span.skill__name__1AaCO {
  display: block;
  line-height: 2.2vh;
  font-size: 1.8vh;
  text-align: center;
  color: black;
}

@media screen and (max-width: 1024px) {
  div.skill__skill__nfv-O {
    margin-left: 0vw;
    width: 18vw;
    display: inline-block;
    border-radius: 2vh;
    margin-bottom: 2vh;
    padding-top: 0.5vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    text-align: center;
  }

  img.skill__logo__6CIdW {
    width: 7vh;
    height: auto;
    max-height: 10vh;
    display: inline-block;
  }

  span.skill__name__1AaCO {
    display: block;
    line-height: 2.2vh;
    font-size: 1.3vh;
    text-align: center;
    color: black;
  }
}

div.portfolio__portfolio__7SQNT {
  width: 100vw;
  padding-top: 3vh;
  margin-top: 15vh;
  text-align: center;
}

div.portfolio__portfolio__7SQNT h2.portfolio__section_heading__3YHJf {
  display: inline-block;
  font-size: 4vh;
  border-bottom: solid 4px rgba(0, 0, 0, 0.6);
  color: rgba(0, 0, 0, 0.6);
  padding-bottom: 1.5vh;
}

div.portfolio__project_filter_container__1-1Ht {
  width: 100vw;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

div.portfolio__project_filter_container__1-1Ht button.portfolio__filter_button__lZYfx {
  height: 5vh;
  width: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 2.5vh;
  box-shadow: none;
  outline: none;
  border: solid 2px #f9375e;
  margin-right: 1.5vw;
  margin-left: 1.5vw;
  font-size: 2.6vh;
  color: #f9375e;
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
}

div.portfolio__project_filter_container__1-1Ht button.portfolio__filter_button__lZYfx:hover {
  -webkit-transform: translateY(-0.2vh);
          transform: translateY(-0.2vh);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
}

div.portfolio__project_filter_container__1-1Ht button.portfolio__filter_button__lZYfx:active {
  -webkit-transform: translateY(-0.1vh);
          transform: translateY(-0.1vh);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}

div.portfolio__project_filter_container__1-1Ht button.portfolio__filter_button__lZYfx.portfolio__filter_active__g4psQ {
  color: white;
  background-color: #f9375e;
}

div.portfolio__project_container__2h9-B {
  width: 100vw;
  margin-top: 3vh;
  display: block;
  padding: 0vh 12.5vw 10vh 12.5vw;
  margin-bottom: 15vh;
  margin-top: 5vh;
  position: relative;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 {
  width: 20vw;
  height: 20vw;
  display: inline-block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: 0.3s;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1.portfolio__hidden__3m_It {
  -webkit-transform: scale(0);
          transform: scale(0);
  margin-left: -10vw;
  margin-right: -10vw;
  margin-top: -10vw;
  margin-bottom: -10vw;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1.portfolio__display_none__2LCFa {
  display: none;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 img {
  width: 20vw;
  height: 20vw;
  transition: 0.6s;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 img.portfolio__transparent__1beju {
  opacity: 0;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 div.portfolio__info__TUk_O {
  position: absolute;
  top: 5vh;
  display: block;
  text-align: center;
  width: 100%;
  transition: 0.4s;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 div.portfolio__info__TUk_O.portfolio__hide__1V2B_ {
  -webkit-transform: translateY(-15vh);
          transform: translateY(-15vh);
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 div.portfolio__info__TUk_O span.portfolio__title__1qDIV {
  display: block;
  font-size: 3.2vh;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 div.portfolio__info__TUk_O span.portfolio__tech__lYnqB {
  display: block;
  font-size: 2.6vh;
  margin-top: 1vh;
  color: #f9375e;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 button.portfolio__learn_more_button__3PRCr {
  background-color: #f9375e;
  position: absolute;
  bottom: 5vh;
  left: 0vh;
  width: 9vw;
  height: 4vh;
  font-size: 2.2vh;
  border-radius: 1vh;
  border: none;
  margin-left: 5.5vw;
  color: white;
  transition: 0.4s;
  cursor: pointer;
  outline: none;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 button.portfolio__learn_more_button__3PRCr:hover {
  -webkit-transform: translateY(-0.3vh);
          transform: translateY(-0.3vh);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  color: #f9375e;
  border: solid 2px #f9375e;
  background-color: white;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 button.portfolio__learn_more_button__3PRCr:active {
  -webkit-transform: translateY(-0.115vh);
          transform: translateY(-0.115vh);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  color: #f9375e;
  border: solid 2px #f9375e;
  background-color: white;
}

div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 button.portfolio__learn_more_button__3PRCr.portfolio__hide__1V2B_ {
  -webkit-transform: translateY(10vh);
          transform: translateY(10vh);
}

@media (max-width: 1024px) {
  div.portfolio__portfolio__7SQNT {
    margin-top: 5vh;
  }

  div.portfolio__portfolio__7SQNT h2.portfolio__section_heading__3YHJf {
  }

  div.portfolio__project_filter_container__1-1Ht {
    margin-top: 1.5vh;
    margin-bottom: 1.5vh;
  }

  div.portfolio__project_filter_container__1-1Ht button.portfolio__filter_button__lZYfx {
    height: 4vh;
    margin-right: 2vw;
    margin-left: 2vw;
    font-size: 2vh;
    margin-bottom: 1vh;
    padding-left: 3vw;
    padding-right: 2vw;
  }

  div.portfolio__project_filter_container__1-1Ht button.portfolio__filter_button__lZYfx:hover {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
    box-shadow: none;
  }

  div.portfolio__project_filter_container__1-1Ht button.portfolio__filter_button__lZYfx:active {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
    box-shadow: none;
  }

  div.portfolio__project_container__2h9-B {
    padding: 0vh 5vw 5vh 5vw;
    margin-bottom: 8vh;
    margin-top: 3vh;
    display: block;
  }

  div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 {
    width: 60vw;
    height: 60vw;
    margin-left: 15vw;
    display: block;
    margin-bottom: 2vh;
  }

  div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1.portfolio__hidden__3m_It {
    margin-left: -30vw;
    margin-right: -30vw;
    margin-top: -30vw;
    margin-bottom: -30vw;
  }

  div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1.portfolio__display_none__2LCFa {
    display: none;
  }

  div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 img {
    width: 60vw;
    height: 60vw;
    transition: 0.6s;
  }

  div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 img.portfolio__transparent__1beju {
    opacity: 0;
  }

  div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 div.portfolio__info__TUk_O {
    display: none;
  }

  div.portfolio__project_container__2h9-B div.portfolio__project__1MrG1 button.portfolio__learn_more_button__3PRCr {
    display: none;
  }
}

div.portfolio_preview__preview_window__22ifw {
  position: fixed;
  height: 82vh;
  width: 50vw;
  top: 8vh;
  margin-left: 25vw;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  -webkit-transform: scale(1);
          transform: scale(1);
}

div.portfolio_preview__preview_window__22ifw.portfolio_preview__hidden__pmv4E {
  -webkit-transform: scale(0);
          transform: scale(0);
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__image_scroll_container__245Ft {
  width: 50vw;
  height: 50vh;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;
  display: block;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__image_container__1-l5q {
  width: 50vw;
  height: 50vh;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  transition: 0.3s;
  background-size: 100%;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__image_scroll_container__245Ft div.portfolio_preview__button_holder__3H-4Q {
  width: 50vw;
  height: 8.3vh;
  position: absolute;
  bottom: 0;
  border-bottom: solid 0.3vh black;
}

div.portfolio_preview__preview_window__22ifw
  div.portfolio_preview__image_scroll_container__245Ft
  div.portfolio_preview__button_holder__3H-4Q
  div.portfolio_preview__image_button__3v7a3 {
  height: 8vh;
  width: 8vh;
  background-color: #5f6060;
  display: inline-block;
  margin-bottom: 0.3vh;
  cursor: pointer;
}

div.portfolio_preview__preview_window__22ifw
  div.portfolio_preview__image_scroll_container__245Ft
  div.portfolio_preview__button_holder__3H-4Q
  div.portfolio_preview__image_button__3v7a3.portfolio_preview__previous__10zfN {
  float: left;
}

div.portfolio_preview__preview_window__22ifw
  div.portfolio_preview__image_scroll_container__245Ft
  div.portfolio_preview__button_holder__3H-4Q
  div.portfolio_preview__image_button__3v7a3.portfolio_preview__next__3P5ch {
  float: right;
}

div.portfolio_preview__preview_window__22ifw
  div.portfolio_preview__image_scroll_container__245Ft
  div.portfolio_preview__button_holder__3H-4Q
  div.portfolio_preview__image_button__3v7a3
  img.portfolio_preview__arrow__20Oh- {
  height: 60%;
  width: 60%;
  margin: 20% 20% 20% 20%;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__text_container__1z148 {
  height: 23vh;
  width: 50vw;
  padding: 3vh 2vw 3vh 2vw;
  -webkit-filter: blur(0.000001px);
  overflow: scroll;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__text_container__1z148 span.portfolio_preview__heading__vMNYw {
  display: block;
  font-size: 3vh;
  margin-bottom: 1vh;
  font-weight: 300;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__text_container__1z148 span.portfolio_preview__subheading__3QTsn {
  display: block;
  font-size: 2vh;
  margin-bottom: 2vh;
  color: grey;
  font-weight: 300;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__text_container__1z148 p {
  font-weight: 300;
  font-size: 2vh;
  line-height: 3vh;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw {
  height: 8vh;
  position: relative;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x {
  height: auto;
  font-size: 2.5vh;
  position: absolute;
  bottom: 1.5vh;
  margin-left: 2vw;
  padding: 0.5vh 1vw 0.5vh 1vw;
  border-radius: 0.5vh;
  width: auto;
  box-shadow: none;
  outline: none;
  border: solid 2px #f9375e;
  color: #f9375e;
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x a {
  text-decoration: none;
  color: #f9375e;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x:hover {
  background-color: #f9375e;
}

div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x:hover a {
  color: white;
}

@media (max-width: 1024px) {
  div.portfolio_preview__preview_window__22ifw {
    height: 68vh;
    width: 90vw;
    top: 8vh;
    margin-left: 5vw;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__image_scroll_container__245Ft {
    width: 90vw;
    height: 20vh;
    border-bottom: solid 1px gray;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__image_container__1-l5q {
    width: 90vw;
    height: 20vh;
    background-size: 100%;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__image_container__1-l5q.portfolio_preview__auto_scroll__uB-cr {
    -webkit-animation-name: portfolio_preview__example__eXj-C;
            animation-name: portfolio_preview__example__eXj-C;
    -webkit-animation-duration: 15s;
            animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }

  @-webkit-keyframes portfolio_preview__example__eXj-C {
    0% {
      -webkit-transform: translateX(0vw);
              transform: translateX(0vw);
    }
    50% {
      -webkit-transform: translateX(-180vw);
              transform: translateX(-180vw);
    }
    100% {
      -webkit-transform: translateX(0vw);
              transform: translateX(0vw);
    }
  }

  @keyframes portfolio_preview__example__eXj-C {
    0% {
      -webkit-transform: translateX(0vw);
              transform: translateX(0vw);
    }
    50% {
      -webkit-transform: translateX(-180vw);
              transform: translateX(-180vw);
    }
    100% {
      -webkit-transform: translateX(0vw);
              transform: translateX(0vw);
    }
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__image_scroll_container__245Ft div.portfolio_preview__button_holder__3H-4Q {
    width: 90vw;
    height: 4.3vh;
    position: absolute;
    border-bottom: solid 0.3vh black;
    display: none;
  }

  div.portfolio_preview__preview_window__22ifw
    div.portfolio_preview__image_scroll_container__245Ft
    div.portfolio_preview__button_holder__3H-4Q
    div.portfolio_preview__image_button__3v7a3 {
    height: 4vh;
    width: 4vh;
  }

  div.portfolio_preview__preview_window__22ifw
    div.portfolio_preview__image_scroll_container__245Ft
    div.portfolio_preview__button_holder__3H-4Q
    div.portfolio_preview__image_button__3v7a3.portfolio_preview__previous__10zfN {
    float: left;
  }

  div.portfolio_preview__preview_window__22ifw
    div.portfolio_preview__image_scroll_container__245Ft
    div.portfolio_preview__button_holder__3H-4Q
    div.portfolio_preview__image_button__3v7a3.portfolio_preview__next__3P5ch {
    float: right;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__text_container__1z148 {
    height: auto;
    max-height: 35vh;
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__text_container__1z148 span.portfolio_preview__heading__vMNYw {
    margin-bottom: 2vh;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__text_container__1z148 p {
    font-size: 1.9vh;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw {
    height: 8vh;
    position: relative;
    display: block;
    text-align: center;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x {
    height: auto;
    font-size: 2.5vh;
    position: relative;
    display: inline-block;
    bottom: 0vh;
    margin-left: 0vw;
    margin-top: 3vh;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x a {
    text-decoration: none;
    color: #f9375e;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x:hover {
    background-color: #f9375e;
  }

  div.portfolio_preview__preview_window__22ifw div.portfolio_preview__button_container__3rbTw button.portfolio_preview__visit_site__rsL0x:hover a {
    color: white;
  }
}

div.backdrop__backdrop__3kh8K {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.38);
    z-index: 10;
    cursor: pointer;
    transition: 0.5s;
}

div.scroll_to_top__scroll_to_top__1wNNO {
  width: 8vh;
  height: 8vh;
  position: fixed;
  bottom: 4vh;
  right: 4vh;
  border: solid 2px black;
  border-radius: 20vh;
  cursor: pointer;
  background-color: white;
  z-index: 10;
  opacity: 0.85;
  transition: 0.3s;
}

div.scroll_to_top__scroll_to_top__1wNNO.scroll_to_top__hidden__1zWrA {
  opacity: 0;
}

div.scroll_to_top__scroll_to_top__1wNNO img.scroll_to_top__arrow__1AKre {
  width: 50%;
  height: 50%;
  margin-top: 23%;
  margin-left: 25%;
}

@media (max-width: 1024px) {
  div.scroll_to_top__scroll_to_top__1wNNO {
    width: 6vh;
    height: 6vh;
    bottom: 2vh;
    right: 2vh;
  }
}

