.navbar {
  width: 100vw;
  height: 14vh;
}

img.logo {
  height: 9vh;
  width: 9vh;
  margin-left: 5vh;
  margin-top: 5vh;
}

span.nav_item {
  text-decoration: none;
  float: right;
  font-size: 2.6vh;
  line-height: 3vh;
  margin-top: 5vh;
  margin-right: 5vw;
  color: black;
  padding-bottom: 1vh;
}

a.nav_item {
  text-decoration: none;
  float: right;
  font-size: 2.6vh;
  line-height: 3vh;
  margin-top: 5vh;
  margin-right: 5vw;
  color: black;
  padding-bottom: 1vh;
}

span.nav_item:hover {
  border-bottom: solid 2px black;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  img.logo {
    height: 6vh;
    width: 6vh;
    margin-left: 3vh;
    margin-top: 3vh;
  }

  span.nav_item {
    margin-top: 4vh;
    margin-right: 6vw;
    font-weight: 300;
    font-size: 2.2vh;
  }
}
