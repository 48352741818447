div.footer {
  height: 10vh;
  width: 100vw;
  background-image: linear-gradient(to right, #f9375e, #fa6251);
  margin-top: 0vh;
  position: relative;
}

span.name {
  display: block;
  color: white;
  text-align: center;
  font-size: 2vh;
  position: absolute;
  bottom: 3.5vh;
  width: 100vw;
  font-weight: 500;
}
