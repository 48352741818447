div.main_details_container {
  width: 100vw;
  margin-top: 0vh;
  height: 81vh;
  position: relative;
}

div.main_details_container h1 {
  margin-top: 0;
  text-align: center;
  font-weight: 400;
  font-size: 40px;
  margin-right: 2vw;
  margin-left: 2vw;
}

img.profile_pic {
  width: 12vw;
  height: 12vw;
  object-fit: cover;
  border: solid 3px red;
  border-radius: 100vw;
  padding: 3px;
  margin-left: 44vw;
  margin-top: 3vh;
}

div.main_details_container h5.subtle_text {
  font-weight: 300;
  color: grey;
  text-align: center;
  font-size: 15px;
  margin-top: 0;
}

div.main_details_container h2 {
  font-size: 3.4vh;
  margin-top: 3vh;
}

div.main_details_container h3 {
  font-size: 2.8vh;
  margin-top: 0vh;
}
div.icon_container {
  display: block;
  height: 7vh;
  text-align: center;
  margin-top: 3vh;
}

div.icon_container img.icon {
  margin-top: 0.5vh;
  display: inline-block;
  height: 5vh;
  margin-left: 1vw;
  margin-right: 1vw;
  cursor: pointer;
  transition: 0.3s;
}

div.icon_container img.icon:hover {
  transform: translateY(-0.7vh);
}

div.my_story_button {
  margin-top: 10vh;
  cursor: pointer;
  -webkit-animation: pulse linear 2s infinite;
  animation: pulse linear 2s infinite;
}

div.my_story_button h4 {
  text-align: center;
  font-weight: 400;
  font-size: 2.5vh;
  margin-bottom: 2vh;
  margin-top: 5vh;
  opacity: 0.7;
}

div.my_story_button img.arrow {
  width: 4vw;
  display: block;
  margin-left: 48vw;
  margin-top: 0vh;
  opacity: 0.6;
}

img.three_lines {
  height: 58vh;
  position: absolute;
  bottom: 0;
  right: 0;
}

h2 {
  margin-top: 2vh;
  text-align: center;
  font-weight: 400;
}

h3 {
  text-align: center;
  font-weight: 400;
}

h3.light {
  font-weight: 300;
}

div.summary_text {
  width: 100vw;
  height: 24vh;
  margin-top: 0vh;
  background-image: linear-gradient(to right, #f9375e, #fb8847);
  padding: 2vh 5vw 2vh 5vw;
  color: white;
}

div.summary_text p {
  text-align: center;
  font-size: 2.5vh;
  line-height: 4vh;
}

@media screen and (max-width: 700px) {
  div.main_details_container {
    height: 85.5vh;
  }

  div.main_details_container h5.subtle_text {
    font-size: 12px;
    margin-top: 0;
  }

  div.main_details_container h1 {
    margin-top: 0vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-weight: 300;
    line-height: 30px;
    font-size: 20px;
  }

  img.profile_pic {
    width: 40vw;
    height: 40vw;
    margin-left: 30vw;
  }

  div.main_details_container h2 {
    font-size: 3.4vh;
    margin-top: 1.5vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-weight: 300;
  }

  div.main_details_container h3 {
    font-size: 2.8vh;
    margin-top: 0vh;
    margin-left: 5vw;
    margin-right: 5vw;
    font-weight: 300;
    line-height: 4vh;
  }
  div.icon_container {
    display: block;
    height: 7vh;
    text-align: center;
    margin-top: 3vh;
  }

  div.icon_container img.icon {
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 0.5vh;
    display: inline-block;
    height: 3.8vh;
  }

  div.icon_container img.icon:hover {
    transform: translateY(0);
  }

  div.my_story_button {
    margin-top: 5vh;
    text-align: center;
  }

  div.my_story_button h4 {
  }

  div.my_story_button img.arrow {
    width: 8vw;
    margin-left: 45vw;
  }

  img.three_lines {
    height: 18vh;
    z-index: -1;
    opacity: 0.9;
  }

  div.summary_text {
    width: 100vw;
    height: 37vh;
    padding: 1vh 2vw 1vh 2vw;
    color: white;
  }

  div.summary_text p {
    text-align: center;
    font-size: 2.2vh;
    line-height: 4vh;
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: translateY(0vh);
  }
  50% {
    transform: translateY(-1.5vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@keyframes pulse {
  0% {
    transform: translateY(0vh);
  }
  50% {
    transform: translateY(-1.5vh);
  }
  100% {
    transform: translateY(0vh);
  }
}

@media screen and (max-width: 1100px) {
  img.three_lines {
    height: 24vh;
  }
}
