div.preview_window {
  position: fixed;
  height: 82vh;
  width: 50vw;
  top: 8vh;
  margin-left: 25vw;
  z-index: 100;
  background-color: white;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  transform: scale(1);
}

div.preview_window.hidden {
  transform: scale(0);
}

div.preview_window div.image_scroll_container {
  width: 50vw;
  height: 50vh;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;
  display: block;
}

div.preview_window div.image_container {
  width: 50vw;
  height: 50vh;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  transition: 0.3s;
  background-size: 100%;
}

div.preview_window div.image_scroll_container div.button_holder {
  width: 50vw;
  height: 8.3vh;
  position: absolute;
  bottom: 0;
  border-bottom: solid 0.3vh black;
}

div.preview_window
  div.image_scroll_container
  div.button_holder
  div.image_button {
  height: 8vh;
  width: 8vh;
  background-color: #5f6060;
  display: inline-block;
  margin-bottom: 0.3vh;
  cursor: pointer;
}

div.preview_window
  div.image_scroll_container
  div.button_holder
  div.image_button.previous {
  float: left;
}

div.preview_window
  div.image_scroll_container
  div.button_holder
  div.image_button.next {
  float: right;
}

div.preview_window
  div.image_scroll_container
  div.button_holder
  div.image_button
  img.arrow {
  height: 60%;
  width: 60%;
  margin: 20% 20% 20% 20%;
}

div.preview_window div.text_container {
  height: 23vh;
  width: 50vw;
  padding: 3vh 2vw 3vh 2vw;
  -webkit-filter: blur(0.000001px);
  overflow: scroll;
}

div.preview_window div.text_container span.heading {
  display: block;
  font-size: 3vh;
  margin-bottom: 1vh;
  font-weight: 300;
}

div.preview_window div.text_container span.subheading {
  display: block;
  font-size: 2vh;
  margin-bottom: 2vh;
  color: grey;
  font-weight: 300;
}

div.preview_window div.text_container p {
  font-weight: 300;
  font-size: 2vh;
  line-height: 3vh;
}

div.preview_window div.button_container {
  height: 8vh;
  position: relative;
}

div.preview_window div.button_container button.visit_site {
  height: auto;
  font-size: 2.5vh;
  position: absolute;
  bottom: 1.5vh;
  margin-left: 2vw;
  padding: 0.5vh 1vw 0.5vh 1vw;
  border-radius: 0.5vh;
  width: auto;
  box-shadow: none;
  outline: none;
  border: solid 2px #f9375e;
  color: #f9375e;
  background-color: white;
  cursor: pointer;
  transition: 0.3s;
}

div.preview_window div.button_container button.visit_site a {
  text-decoration: none;
  color: #f9375e;
}

div.preview_window div.button_container button.visit_site:hover {
  background-color: #f9375e;
}

div.preview_window div.button_container button.visit_site:hover a {
  color: white;
}

@media (max-width: 1024px) {
  div.preview_window {
    height: 68vh;
    width: 90vw;
    top: 8vh;
    margin-left: 5vw;
  }

  div.preview_window div.image_scroll_container {
    width: 90vw;
    height: 20vh;
    border-bottom: solid 1px gray;
  }

  div.preview_window div.image_container {
    width: 90vw;
    height: 20vh;
    background-size: 100%;
  }

  div.preview_window div.image_container.auto_scroll {
    animation-name: example;
    animation-duration: 15s;
    animation-iteration-count: infinite;
  }

  @keyframes example {
    0% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(-180vw);
    }
    100% {
      transform: translateX(0vw);
    }
  }

  div.preview_window div.image_scroll_container div.button_holder {
    width: 90vw;
    height: 4.3vh;
    position: absolute;
    border-bottom: solid 0.3vh black;
    display: none;
  }

  div.preview_window
    div.image_scroll_container
    div.button_holder
    div.image_button {
    height: 4vh;
    width: 4vh;
  }

  div.preview_window
    div.image_scroll_container
    div.button_holder
    div.image_button.previous {
    float: left;
  }

  div.preview_window
    div.image_scroll_container
    div.button_holder
    div.image_button.next {
    float: right;
  }

  div.preview_window div.text_container {
    height: auto;
    max-height: 35vh;
    width: 90vw;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  div.preview_window div.text_container span.heading {
    margin-bottom: 2vh;
  }

  div.preview_window div.text_container p {
    font-size: 1.9vh;
  }

  div.preview_window div.button_container {
    height: 8vh;
    position: relative;
    display: block;
    text-align: center;
  }

  div.preview_window div.button_container button.visit_site {
    height: auto;
    font-size: 2.5vh;
    position: relative;
    display: inline-block;
    bottom: 0vh;
    margin-left: 0vw;
    margin-top: 3vh;
  }

  div.preview_window div.button_container button.visit_site a {
    text-decoration: none;
    color: #f9375e;
  }

  div.preview_window div.button_container button.visit_site:hover {
    background-color: #f9375e;
  }

  div.preview_window div.button_container button.visit_site:hover a {
    color: white;
  }
}
